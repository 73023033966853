import '../styles/globals.css';
import Script from '../helpers/next-script';

function SafeHydrate({ children }) {
  return (
    <div suppressHydrationWarning>
      {typeof window === 'undefined' ? null : children}
    </div>
  );
}

function MyApp({ Component, pageProps }) {
  return (
    <>
      <Script strategy="beforeInteractive" src="/__ENV.js"/>
      <Script strategy="beforeInteractive" src="./lib/gk/AppEnablementConnector.js"/>
      <Script strategy="beforeInteractive" src="./lib/gk/Common.js"/>
      <Script strategy="beforeInteractive" src="./lib/gk/Masterdata.js"/>
      <Script strategy="beforeInteractive" src="./lib/gk/ExternalMasterdata.js"/>
      <Script strategy="beforeInteractive" src="./lib/gk/Pos.js"/>
      <Script strategy="beforeInteractive" src="./lib/gk/Pos_CST.js"/>
      <SafeHydrate><Component {...pageProps} /></SafeHydrate>
    </>
  );
}

export default MyApp;
